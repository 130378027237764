import React from "react";

import { observer } from "mobx-react";

import AppBar from "../../components/appBar";
import User from "./../user/user";
import Loan from "./../loan/loan";
import PageNotFound from "./../../common/pageNotFound";
import Role from "./../role/role";

import DetailRoute from "./../../routes/detailRoute";
import CreditLine from "./../creditLine/creditLine";
import {
    findByPathName,
    containsAnyDigit
} from "./../../utils/helper";
import AddRoute from "../../routes/addRoute";
import UnderWriting from "../underwriting/underWriting";
import { getLocalStorage, saveLocalStorage } from "../../utils/localStorage";
import { Redirect } from "react-router";
import WaiverListing from "../waiver/waiverListing";
import PayDeduction from "../payDeduction/deduction";
import ManualApprove from "../underwriting/manualApprove";
import LoanSettlement from "../waiver/loanSettlement";
import "./home.css"
import SideBar from "../../components/sidebar/sidebar";
import SmsCampaign from "../campaign/campaign";
import LoanPtpListing from "../ptp/loanPtpListing";
import ClPtpListing from "../ptp/clPtpListing";
import PayOutScreen from "../payout/payout";
import DashboardScreen from "../dashboard/dashboard";
import EWA from "./../../screens/ewa";
import WarningLetter from "../warningLetter";
import { Colors } from "../../styles";
import { PRODUCTS } from "../../common/constants";
import "./home.css";


const ROLE_VALUES = {
    "MPREQUEST": ["ROLE_MP_REQUEST"],
    "DASHBOARD": ["ROLE_CRM_ADMIN", "ROLE_ADMIN_RO", "ROLE_ADMIN"],
    "ADMIN": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "USERS": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "LOANS": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "CREDITLINES": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "WAIVERS": ["ROLE_ADMIN", "ROLE_WAIVER", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "MANUALCHECK": ["ROLE_ADMIN_RO", "ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_MRRO", "ROLE_MRRW",],
    "UNDERWRITING": ["ROLE_ADMIN", "ROLE_UW", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "SETTLEMENT": ["ROLE_ADMIN", "ROLE_WAIVER", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "CAMPAIGNS": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "DEDUCTION": ["ROLE_ADMIN", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "LOANPTP": ["ROLE_ADMIN", "ROLE_CE", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "CREDITLINEPTP": ["ROLE_ADMIN", "ROLE_CE", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "WARNINGLETTER": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "EWA": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
}

class Home extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            hasLocalAuth: getLocalStorage('LmsUserDetails'),
            product: 'loan'
        }
    }

    restrictedPage = () => {
        return (<>
            <div
                style={{
                    margin: "210px 540px 400px 540px",
                    fontSize: 40,
                    color: "red"
                }}
            >
                You are restricted to visit this page
            </div>
            {
                setTimeout(() => {
                    const { history } = this.props;
                    history.goBack();
                }, 1000)
            }
        </>)
    }

    componentDidMount() {
        const product = getLocalStorage('product');
        console.log('product', product);
        if (product) {
            this.setState({ product: product })
        } else {
            saveLocalStorage('product', 'loan');
            this.setState({ product: 'loan' })
        }
    }



    checkPermission = (PERMISSIONS) => {
        const { hasLocalAuth } = this.state;
        let conditionCheck = false;
        if (hasLocalAuth) {
            const { role } = hasLocalAuth;
            for (let item of PERMISSIONS) {
                if (role.includes(item)) {
                    conditionCheck = true;
                    break;
                }
            }
        }
        return conditionCheck;
    }



    renderModule = () => {
        const { location } = this.props;
        const { hasLocalAuth } = this.state;
        let pathName = findByPathName(location);

        if (!hasLocalAuth) pathName = 'noAuth';
        switch (pathName) {
            case "user":
                return this.checkPermission(ROLE_VALUES.USERS) ? <User {...this.props} /> : this.restrictedPage();

            case "loan":
                return this.checkPermission(ROLE_VALUES.LOANS) ? <Loan {...this.props} /> : this.restrictedPage();

            case "ewa":
                return this.checkPermission(ROLE_VALUES.EWA) ? <EWA {...this.props} /> : this.restrictedPage();

            case "creditLine":
                return this.checkPermission(ROLE_VALUES.CREDITLINES) ? <CreditLine {...this.props} /> : this.restrictedPage();

            case "role":
                return this.checkPermission(ROLE_VALUES.ADMIN) ? <Role {...this.props} /> : this.restrictedPage();

            case "underWriting":
                return this.checkPermission(ROLE_VALUES.UNDERWRITING) ? <UnderWriting {...this.props} /> : this.restrictedPage();

            case "manualCheck":
                return this.checkPermission(ROLE_VALUES.MANUALCHECK) ? <ManualApprove {...this.props} /> : this.restrictedPage();

            case "waivers":
                return this.checkPermission(ROLE_VALUES.WAIVERS) ? <WaiverListing {...this.props} /> : this.restrictedPage();

            case "deduction":
                return this.checkPermission(ROLE_VALUES.DEDUCTION) ? <PayDeduction {...this.props} /> : this.restrictedPage();

            case "autoUnderwriting":
                return this.checkPermission(ROLE_VALUES.MPREQUEST) ? <PayOutScreen {...this.props} /> : this.restrictedPage();

            case "settlement":
                return this.checkPermission(ROLE_VALUES.SETTLEMENT) ? <LoanSettlement {...this.props} /> : this.restrictedPage();

            case "smsCampaign":
                return this.checkPermission(ROLE_VALUES.CAMPAIGNS) ? <SmsCampaign {...this.props} /> : this.restrictedPage();

            case "ptpLoanList":
                return this.checkPermission(ROLE_VALUES.LOANPTP) ? <LoanPtpListing {...this.props} /> : this.restrictedPage();

            case "ptpClList":
                return this.checkPermission(ROLE_VALUES.CREDITLINEPTP) ? <ClPtpListing {...this.props} /> : this.restrictedPage();

            case "dashboard":
                return this.checkPermission(ROLE_VALUES.DASHBOARD) ? <DashboardScreen {...this.props} /> : this.restrictedPage();

            case "warningLetter":
                return this.checkPermission(ROLE_VALUES.WARNINGLETTER) ? <WarningLetter {...this.props} /> : this.restrictedPage();

            case containsAnyDigit(pathName):
                return <DetailRoute />

            case "addRole":
                return this.checkPermission(ROLE_VALUES.ADMIN) ? <AddRoute /> : this.restrictedPage();

            case "noAuth":
                return <Redirect to="/login" />

            default:
                return <PageNotFound />;
        }
    }

    onProductChange = (e) => {
        const { value } = e.target;
        const { history, location } = this.props;
        this.setState({ product: value })
        saveLocalStorage('product', value);
        history.push(`/home/${value}`);
    }

    render () {
        return (<>
            <div className="App">
                <div className="container">
                    <SideBar {...this.props} product={this.state.product} />
                    <div className="main">
                        <AppBar {...this.props} />
                        <select name="products" onChange={this.onProductChange} value={this.state.product} placeholder="Select Product" className="select-product" id="product-select">
                            {PRODUCTS.map(it => <option key={it.value} value={it.value}>{it.label}</option>)}
                        </select>
                        <div style={{ height: 50, backgroundColor: Colors.primary }}></div>
                        {this.renderModule()}
                    </div>
                </div>
            </div>
        </>);
    }
}

export default observer(Home);
